@import "https://use.typekit.net/eat8ium.css";
body {
  font-family: jaf-domus-titling-web, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.nav {
  color: var(--primary);
}
/*# sourceMappingURL=index.b516d651.css.map */
