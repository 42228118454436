@import url("https://use.typekit.net/eat8ium.css");

body {
  font-family: "jaf-domus-titling-web", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.nav {
  color: var(--primary);
}